//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import vuedraggable from 'vuedraggable'
import uploadSource from '@/components/uploadSource'
export default {
  components: {
    uploadSource,
    vuedraggable
  },
  props: {
    // 是否需要上传多个素材
    isMany: {
      type: Boolean,
      default: false
    },
    // 单个素材的默认路径
    path: {
      type: String,
      default: ''
    },
    // 多个素材的默认路径
    manyPath: {
      type: Array,
      default: () => []
    },
    isManyMax: {
      type: Number,
      default: null
    },
    // 此时素材组件的类型 image -- video
    type: {
      type: String,
      default: 'image'
    },
    // 素材展示的行内样式
    showStyle: {
      type: Object,
      default: () => {
        return {
          width: '110px',
          height: '110px'
        }
      }
    },
    /**
     * 类别判断
     * 0-站点内正常上传图片
     * 1-站点外上传图片
     * 2-直播上传图片素材
     */
    url: {
      type: Number,
      default: 0
    },
    maxSize: {
      type: Number,
      default: 0
    },
    liveStatus: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isOpenUploadSource: false
    }
  },
  methods: {
    changeStatus(val) {
      this.isOpenUploadSource = val
    },
    getSource(list) {
      if (this.isMany) {
        this.$emit('getSource', list)
      } else {
        this.$emit('getSource', list[0])
      }
    },
    removeThis(index) {
      // 多选的情况下需要传递删除的索引
      if (index !== null) {
        this.$emit('removeThis', index)
      } else {
        this.$emit('removeThis')
      }
    },
    // 拖动图片排序
    dragEnd() {
      this.$emit('changeOrder', this.manyPath)
    }
  }
}
