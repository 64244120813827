//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    activeName: {
      type: String,
      default: () => null
    },
    isSave: {
      type: Boolean,
      default: () => null
    }
  }
}
