//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LookGroup from '@/components/lookGroup.vue'
import { getfilesize, getSourceWidthHeight } from '@/util/util.js'
export default {
  components: {
    LookGroup
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    /**
     * 类别判断
     * 0-站点内正常上传图片
     * 1-站点外上传图片
     * 2-直播上传图片素材
     */
    url: {
      type: Number,
      default: 0
    },
    manyPath: {
      type: Array,
      default: () => []
    },
    isMany: {
      type: Boolean,
      default: false
    },
    isManyMax: {
      type: Number,
      default: null
    },
    goUp: {
      type: Boolean,
      default: false
    },
    nowGroup: {
      type: Object,
      default: () => {
        return {
          id: -1,
          name: '全部图片'
        }
      }
    },
    type: {
      type: String,
      default: 'image'
    },
    maxSize: {
      type: Number,
      default: 0
    },
    liveStatus: {
      type: Number,
      default: 0
    }
  },
  computed: {
    alreadySuccess() {
      return this.fileList.filter((item) => item.percentage == 100)
    }
  },
  data() {
    return {
      nowStatus: 0,
      groupList: [],
      sortStyle: 0,
      sortStyleList: [
        { type: 0, name: '最新上传在前' },
        { type: 1, name: '最新上传在后' },
        { type: 2, name: '按图片名升序' },
        { type: 3, name: '按图片名降序' }
      ],
      fileList: [],
      searchName: '',
      contentList: [],
      page: 1,
      rows: 40,
      total: 0,
      isClick: false,
      isSelectList: [],
      id: 1
    }
  },
  methods: {
    dealFnArr(arr, callback = () => {}) {
      arr.map((item) => {
        callback(item)
        if (item._child) {
          this.dealFnArr(item._child, callback)
        }
      })
    },
    goSourceCenter() {
      let routeData = this.$router.resolve('/set/sourceCenter')
      window.open(routeData.href, '_blank')
    },
    // 获取切换的节点
    resultGroup(data) {
      this.nowGroup = data
      this.getImageList(this.nowGroup.id)
    },
    handleClick(status) {
      this.nowStatus = status
    },
    // 获取图片分组
    getGroupList() {
      let that = this
      this.$axios.post(this.$api.sourceCenter.ImagesGroupList).then((res) => {
        if (res.code == 0) {
          that.groupList = res.result.list
          that.dealFnArr(that.groupList, (item) => {
            that.$set(item, 'checked', false)
          })
          this.$nextTick(() => {
            this.$refs.LookGroup.setDefaule(this.nowGroup)
          })
        } else {
          that.$message.error(res.msg)
        }
      })
    },
    // 获取要上传的图片
    getSourceFile($event) {
      let files = $event.target.files
      let arr = []
      if (this.type == 'image' || this.type == 'live') {
        arr = ['.png', '.jpg', '.jpeg', '.gif', '.JPG', '.PNG']
      } else {
        arr = ['.mp4']
      }
      let maxSize = null
      if (this.maxSize) {
        maxSize = this.maxSize
      } else {
        maxSize = this.type == 'video' ? 1024 * 1024 * 20 : 1024 * 1024 * 5
      }
      for (const key in files) {
        if (!isNaN(Number(key))) {
          let str = files[key].name.lastIndexOf('.')
          let res = arr.findIndex((item1) => item1 == files[key].name.substr(str))
          if (res == -1) {
            this.$message({
              message: `${files[key].name}不符合上传类型，已从上传队列删除`,
              type: 'error',
              customClass: 'mzindex'
            })
            continue
          }
          if (files[key].size > maxSize) {
            this.$message({
              message: `${files[key].name}超过此处最大传输(${getfilesize(
                maxSize
              )})限制，已从上传队列删除`,
              type: 'error',
              customClass: 'mzindex'
            })
            continue
          }
          this.fileList.push({
            name: files[key].name,
            size: getfilesize(files[key].size),
            file: files[key],
            percentage: 0,
            error: false,
            errorDetail: ''
          })
        }
      }
      if (this.fileList.length) {
        this.startUpload()
      } else {
        this.$refs.uploadInput.value = ''
      }
    },
    async startUpload() {
      let that = this
      this.nowStatus = 1
      for (let i = 0; i < this.fileList.length; i++) {
        await this.uploadImg(this.fileList[i]).then(async (res) => {
          this.fileList[i].percentage = 100
          let resObj = {
            id: res.id,
            name: this.fileList[i].name,
            type: this.type,
            url: res.path,
            isSelect: true
          }
          if (this.type == 'image' || this.type == 'live') {
            let { width, height } = await getSourceWidthHeight(res.path)
            that.$set(resObj, 'width', width)
            that.$set(resObj, 'height', height)
          } else {
            that.$set(resObj, 'showSize', this.fileList[i].size)
          }
          let chooseObj = {
            id: res.id,
            name: this.fileList[i].name,
            type: this.type,
            path: res.path,
            isSelect: false
          }
          if (this.url === 2) {
            resObj.media_id = res.media_id
            chooseObj.media_id = res.media_id
            resObj.id = this.id
            chooseObj.id = this.id
            this.id++
          }
          if (!this.isMany) {
            // 单选情况
            if (this.isSelectList.length > 0) {
              let id = this.isSelectList[0].id
              let nowSelect = this.contentList.find((item) => item.id === id)
              nowSelect.isSelect = false
              this.isSelectList = []
              if (this.type === 'live' && this.liveStatus == 1) {
                if (Number(resObj.width) > 300 || Number(resObj.height) > 300) {
                  resObj.isSelect = false
                  this.contentList.unshift(resObj)
                  this.$message({
                    message: '图片宽高不符合要求,已取消自动选中',
                    type: 'warning',
                    customClass: 'mzindex'
                  })
                  return
                }
              }
              this.contentList.unshift(resObj)
              this.isSelectList.push(chooseObj)
            } else {
              if (this.type === 'live' && this.liveStatus == 1) {
                if (Number(resObj.width) > 300 || Number(resObj.height) > 300) {
                  resObj.isSelect = false
                  this.contentList.unshift(resObj)
                  this.$message({
                    message: '微信限制直播商品图片宽高不可超过300px',
                    type: 'warning',
                    customClass: 'mzindex'
                  })
                  return
                }
              }
              this.contentList.unshift(resObj)
              this.isSelectList.push(chooseObj)
            }
          } else {
            let checkRes = this.checkMaxChoose(2)
            if (checkRes) {
              this.contentList.unshift(resObj)
              this.isSelectList.push(chooseObj)
            } else {
              resObj.isSelect = false
              this.contentList.unshift(resObj)
            }
          }
        })
      }

      this.$message({
        message: `上传成功`,
        type: 'success'
      })
      setTimeout(() => {
        this.nowStatus = 0
        this.fileList = []
        this.$refs.uploadInput.value = ''
      }, 1000)
    },
    // 调用接口上传图片
    uploadImg(item) {
      return new Promise((resolve, reject) => {
        let formData = new FormData()
        formData.append('files', item.file)
        if (this.url === 2) {
          formData.append('type', 'live')
        }
        // 设置分组
        if (this.nowGroup.id !== -2 && this.nowGroup.id !== -1) {
          formData.append('group_id', this.nowGroup.id)
        }
        this.$axios
          .showProgressUpload(
            this.url === 1
              ? this.$api.user.SystemUpload
              : this.url === 0
              ? this.$api.user.addFiles
              : this.url === 2
              ? // ? this.$api.push.liveRoom.liveImgUp
                this.$api.user.addFiles
              : '',
            formData,
            (data) => {
              let { loaded, total } = data
              let nowPercentage =
                Math.floor((loaded / total) * 100) - 10 >= 0
                  ? Math.floor((loaded / total) * 100) - 10
                  : 0
              this.$set(item, 'percentage', nowPercentage)
            }
          )
          .then((res) => {
            if (res.code == 0) {
              resolve(res.result)
            } else {
              this.fileList = []
              this.nowStatus = 0
              this.$nextTick(() => {
                this.$refs.uploadInput.value = ''
              })
              this.$message.error(res.msg)
            }
          })
          .catch(() => {
            this.fileList = []
            this.nowStatus = 0
            this.$nextTick(() => {
              this.$refs.uploadInput.value = ''
            })
          })
      })
    },
    // 查询当前符合条件的图片
    getImageList(id, status) {
      if (this.type == 'live') {
        this.$message({
          message: '请上传图片',
          type: 'warning',
          customClass: 'mzindex'
        })
        return
      }
      // status 1-> 点击搜索按钮  2->滑动分页查询
      let that = this
      let obj = {
        page: this.page,
        rows: this.rows,
        type: this.type,
        is_image: 1
      }
      if (status == 1) {
        // 清空树形结构高亮
        this.isClick = true
        this.nowGroup.id = -2
        this.$nextTick(() => {
          this.$refs.LookGroup.setDefaule(null)
        })
        this.page = 1
      }
      // 如果需要查询指定分组
      if (id != -1) {
        obj.group_id = id
      }
      if (this.searchName) {
        obj.keywords = this.searchName
      }
      switch (this.sortStyle) {
        case 0:
          obj.order_type = ['create_time', 'desc']
          break
        case 1:
          obj.order_type = ['create_time', 'asc']
          break
        case 2:
          obj.order_type = ['name', 'desc']
          break
        case 3:
          obj.order_type = ['name', 'asc']
          break
        default:
          break
      }
      this.$axios.post(this.$api.user.imgList, obj).then((res) => {
        if (res.code == 0) {
          that.total = res.result.total_number
          res.result.list.map(async (item) => {
            this.$set(item, 'isSelect', false)
            if (that.type == 'image') {
              let { width, height } = await getSourceWidthHeight(item.url)
              that.$set(item, 'width', width)
              that.$set(item, 'height', height)
            } else {
              that.$set(item, 'showSize', getfilesize(item.size))
            }
          })
          if (status === 2) {
            // 分页
            that.contentList.push(...res.result.list)
          } else {
            that.isSelectList = []
            that.contentList = res.result.list
          }
        }
      })
    },
    // 元素滑动到底部搜索下一页
    onLoadNextRow($event) {
      const { scrollTop, clientHeight, scrollHeight } = $event.target
      if (scrollTop + clientHeight === scrollHeight) {
        if (this.page * this.rows <= this.total) {
          this.page++
          if (this.nowGroup.id !== -2) {
            // 树形菜单有高亮
            this.getImageList(this.nowGroup.id, 2)
          } else {
            this.getImageList(-1, 2)
          }
        }
      }
    },
    checkMaxChoose(status) {
      if (this.isManyMax) {
        // 有最大选择数量限制
        if (this.isSelectList.length === this.isManyMax - this.manyPath.length) {
          let str = status == 1 ? `已到素材选中量上限` : '已到最大选中数量,取消自动选中上传图片'
          this.$message({
            message: str,
            type: 'warning',
            customClass: 'mzindex'
          })
          return false
        }
      }
      return true
    },
    // 更改图片的选中状态
    chooseItem(item) {
      if (!item.isSelect) {
        if (!this.checkMaxChoose(1)) {
          return
        }
      }
      if (this.type == 'live' && this.liveStatus == 1) {
        if (item.width > 300 || item.height > 300) {
          this.$message({
            message: '微信限制直播商品图片宽高不可超过300px',
            type: 'warning',
            customClass: 'mzindex'
          })
          return
        }
      }
      if (this.maxSize !== 0) {
        if (this.maxSize < item.size) {
          this.$message({
            message: '该图片大小超过此处最大允许的最大图片大小',
            type: 'warning',
            customClass: 'mzindex'
          })
          return
        }
      }
      item.isSelect = !item.isSelect
      if (this.isMany) {
        // 可以多选
        if (item.isSelect) {
          let itemThis = JSON.parse(JSON.stringify(item))
          this.isSelectList.push({
            id: itemThis.id,
            path: itemThis.url,
            name: itemThis.name,
            isSelect: false
          })
        } else {
          let index = this.isSelectList.findIndex((item1) => item1.id === item.id)
          this.isSelectList.splice(index, 1)
        }
      } else {
        this.contentList.map((item1) => {
          if (item1.id !== item.id) {
            item1.isSelect = false
          }
        })
        if (item.isSelect) {
          let itemThis = JSON.parse(JSON.stringify(item))
          this.isSelectList = [
            {
              id: itemThis.id,
              path: itemThis.url,
              name: itemThis.name,
              isSelect: false
            }
          ]
        } else {
          this.isSelectList = []
        }
      }
    },
    sureSoureList() {
      if (this.isSelectList.length > 0) {
        this.$emit('getSource', this.isSelectList)
        this.$emit('changeStatus', false)
      }
    }
  },
  watch: {
    dialogVisible: {
      handler(val) {
        if (val) {
          if (this.type != 'live') {
            this.getGroupList()
            this.getImageList(-1)
          }
        }
      },
      immediate: true
    }
  }
}
